import React from 'react';
import Header from '../Header/Header';
import MainBanner from '../MainBanner/MainBanner';
import Mission from '../Mission/Mission';
import Projects from '../Projects/Projects';
import Ecosystem from '../Ecosystem/Ecosystem';
import BoostBusiness from '../BoostBusiness/BoostBusiness';
import WhatWeServed from '../WhatWeServed/WhatWeServed';
import TeamLeader from '../TeamLeader/TeamLeader';
import Footer from '../Footer/Footer';

function Home(props) {
	return (
		<React.Fragment>
			<Header />
			<MainBanner />
			<Mission />
			<Projects />
			<Ecosystem />
			<BoostBusiness />
			<WhatWeServed />
			<TeamLeader />
			<Footer />
		</React.Fragment>
	);
}
export default Home;