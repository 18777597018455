import React from "react";
import Header from "../Header/Header";
import About from "../About/About";
// import Ecosystem from '../Ecosystem/Ecosystem';
import Footer from "../Footer/Footer";

function AboutUs(props) {
  return (
    <React.Fragment>
      <Header />
      <About />
      {/* <Ecosystem /> */}
      <Footer />
    </React.Fragment>
  );
}
export default AboutUs;
