import React, {useEffect} from 'react';
import AOS from "aos";
import workflowimg from "../../assets/images/workflow.png";
function Ecosystem(props) {

	useEffect(()=> {
		AOS.init();
	}, [])
	
	return (
		<section id="echosystem" className="echosystem-section">
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="d-flex justify-content-start">
						<div className="echo--info"> 
							<h2 data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">DevTeamSix ecosystem</h2>
						</div>
					</div>
					<div className="col-xxl-5 col-lg-6 col-md-12">
						<div className="echo--detail">
							<p className="mb-4"data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								Creating maps of dam ecosystems can help us to understand and explain where and how the use
								of data creates value. A data ecosystem map can help to identify the key roles and the
								relationships between them. These organizations playing these roles may be operating in the
								public, private and third-sectors. The same organization may also play multiple roles in an
								ecosystem.
							</p>
							<p data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut
								ipsum ex, auctor sed finibus eget, blandit mollis urna. Vivamus elementum ante in ligula
								imperdiet faucibus.
							</p>
							<ul className="information-list" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								<li>
									Ut quis porta mi. Etiam hendrerit orci mauris
								</li>
								<li>
									Beifend tincidunt turpis dictum et
								</li>
								<li>
									Gras facilisis bibendum nunc, sed commodo ex accums
								</li>
							</ul>
							<p className="mb-4 opicty-size" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
								lacing elit, sed do eiusmod tempor incididunt ut labore et dolore m lacing elit, sed do
								eiusmod temp or incididunt ut labore et dolore magna aliqua
							</p>
							<p data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								Creating maps of dam ecosystems can help us to understand and explain where and how the use
								of data creates value. A data ecosystem map can help to identify
							</p>
							<p data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								Creating maps of dam ecosystems can help us to understand and explain where and how the use
								of data creates value. A data ecosystem map can help to identify
							</p>
						</div>
					</div>
					<div className="col-xxl-7 col-lg-6 col-md-12">
						<div className="d-flex justify-content-lg-end justify-content-center workflow">
							<div data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
								<img src={workflowimg} alt="" />
							</div>	
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
export default Ecosystem;