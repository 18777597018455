import React from 'react';
import ZigZag from '../../assets/images/zigzag.png';

function OurProjectCheck(props) {
	return (
		<section id="dev-mission project-page-check" className="mission--info">
			<div className="container">
				<div className="d-flex flex-column justify-content-center align-items-center">
					<div className="devteam-data">
						<div className="tabs-area">
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
									<button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
										data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
										aria-selected="true">CreamPYE</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
										data-bs-target="#pills-profile" type="button" role="tab"
										aria-controls="pills-profile" aria-selected="false">MiniDOGE</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">PYEChart</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">DOGEex</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">PYEWallet</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">MiniPets</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">PYESwap</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
										data-bs-target="#pills-contact" type="button" role="tab"
										aria-controls="pills-contact" aria-selected="false">MiniGames</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
export default OurProjectCheck;