import React, { useEffect } from "react";
import AOS from "aos";
import serviceImg from "../../assets/images/service-img.png";
import zigzag from "../../assets/images/zigzag.png";
import mobileImg from "../../assets/images/mobile-img.png";
import socialSetup from "../../assets/images/social-setup.png";
import electric from "../../assets/images/electrice.png";

function ServicesPage(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="services" className="service---section">
      <div className="container">
        <div clas="d-flex justify-content-center align-items-center">
          <div className="services-info">
            <h2
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-easing="ease-out-cubic"
              class="aos-init aos-animate"
            >
              What We Served
            </h2>
            <h4
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-easing="ease-out-cubic"
              class="aos-init aos-animate"
            >
              Join thousands of satisfied customers using our template globally.
            </h4>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-12">
            <div className="services-inner">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                dApp Development
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Decentralized Applications, Software, APIs, and Integrations
                designed to fit your needs. DevTeamSix has in house experts with
                advanced knowledge to assist in helping you save time and money.
              </p>
              {/* <div className="button-holder services-button">
                <div
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <a className="btn" href="/services">
                    View Detail
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-md-0 mb-4">
            <div className="d-flex justify-content-lg-end justify-content-center service-image">
              <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                <img src={serviceImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex zigzag-2--area justify-content-center align-items-center">
          <div className="partitation">{/* <img src={zigzag} alt="" /> */}</div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-lg-start justify-content-center service-image">
              <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                <img src={mobileImg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-easing="ease-out-cubic"
              class="aos-init aos-animate"
            >
              <div className="services-inner">
                <h3 className="text-right">Mobile Apps</h3>
                <p className="text-right">
                  Our team has extensive experience in creating mobile
                  applications through our strategic process of defining,
                  building and launching a successful product.
                </p>
                {/* <div className="button-holder services-button text-right">
                  <div
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-out-cubic"
                    class="aos-init aos-animate"
                  >
                    <a className="btn" href="/services">
                      View Detail
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex zigzag-2--area justify-content-center align-items-center">
          <div className="partitation">{/* <img src={zigzag} alt="" /> */}</div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-12">
            <div className="services-inner">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Digital Marketing
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                With years of experience in delivering messages through social
                media, email, search engines and websites, we have an extensive
                network of communication.{" "}
              </p>
              {/* <div className="button-holder services-button">
                <div
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <a className="btn" href="/services">
                    View Detail
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-lg-end justify-content-center service-image mb-5">
              <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                <img src={socialSetup} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex zigzag-2--area justify-content-center align-items-center mt-5">
          <div className="partitation">{/* <img src={zigzag} alt="" /> */}</div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-lg-start justify-content-center service-image electric-img">
              <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                <img src={electric} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-easing="ease-out-cubic"
              class="aos-init aos-animate"
            >
              <div className="services-inner">
                <h3 className="text-right" data-aos="zoom-in">
                  Software Development
                </h3>
                <p className="text-right" data-aos="zoom-in">
                  DevTeamSix will design, build, deploy and support you through
                  the creation of your specific software needs. You’ll get
                  better than what you expect.{" "}
                </p>
                {/* <div className="button-holder services-button text-right">
                  <a className="btn" href="/services">
                    View Detail
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ServicesPage;
