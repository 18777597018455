import React, { useEffect } from "react";
import SixImage from "../../assets/images/banner-text.png";
import video from "../../assets/videos/video1.mp4";
import $ from "jquery";
function MainBanner(props) {
  useEffect(() => {
    // $('.overlay-image').addClass("small");
    $(window).on("load", function () {
      setInterval(function () {
        $(".overlay-image").addClass("small");
      }, 2000);
    });
  }, []);
  return (
    <section id="site-banner" className="banner-content">
      <div className="video-area">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/CJmWXAnVj3I?autoplay=1&mute=1&controls=0&rel=0&autohide=2&modestbranding=1;"
          aria-controls="false"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <div className="overlay-image">
        <img src={SixImage} alt="" className="img-fluid" />
      </div>
    </section>
  );
}
export default MainBanner;
