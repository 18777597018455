import React from "react";
import Header from "../Header/Header";
import ServicesPage from "../WhatWeServed/ServicesPage";
// import Ecosystem from '../Ecosystem/Ecosystem';
import Footer from "../Footer/Footer";

function Services(props) {
  return (
    <React.Fragment>
      <Header />
      <ServicesPage />
      <Footer />
    </React.Fragment>
  );
}
export default Services;
