import React from "react";
import Header from "../Header/Header";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";

function ContactPage(props) {
  return (
    <React.Fragment>
      <Header />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}
export default ContactPage;
