import React, {useEffect}  from 'react';
import AOS from "aos";
import { Link } from "react-router-dom";
import FooterLogo from '../../assets/images/footer-logo.png';
import { Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {

	useEffect(()=> {
		AOS.init();
	}, [])

	return (
			<footer id="footer-design">
				<div className="top-footer">
					<Container>
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex">
								<strong className="logo">
									<div data-aos="zoom-in" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
										<Link to="/">
											<img src={FooterLogo} alt=" Site Logo" />
										</Link>
									</div>	
                                </strong>
							</div>
							<div>
								<div className="social-icons d-flex">
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
										<a href="https://www.youtube.com/" target="_blank">
											<FontAwesomeIcon icon={faPlay} />
										</a>
									</div>
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
										<a href="https://twitter.com/?lang=en" target="_blank">
											<FontAwesomeIcon icon={faTwitter} />
										</a>
									</div>
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">	
										<a href="https://www.facebook.com/" target="_blank">
											<FontAwesomeIcon icon={faFacebookF} />
										</a>
									</div>	
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
										<a href="https://www.telegram.com/signup" target="_blank">
											<FontAwesomeIcon icon={faPaperPlane} />
										</a>
									</div>	
								</div>
							</div>
						</div>
						<Row className="justify-content-center align-items-center">
                            <Col lg={6} md={9}>
								<div className="input-text"><p data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Subscribe to our newsletter</p></div>
								<div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
									<div className="input-group mb-3">	
										<input type="text" className="form-control" placeholder="Enter Your Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
										<button className="btn btn-outline-secondary" type="button" id="button-addon2">Subscribe</button>
									</div>
								</div>	
								<div className="d-flex flex-sm-row flex-column justify-content-center align-items-center footer-linking contact-list">
									<ul>
										<li>
                                            <Link to="/apply-for">Apply for a position at DevTeamSix</Link>
                                        </li>
										<li>
                                            <Link to="/latest-news">Latest News</Link>
                                        </li>
										<li>
                                            <Link to="/contact-us">Contact</Link>
                                        </li>
									</ul>
								</div>							  
                            </Col>
                        </Row>
                    </Container>
				</div>
				<div className="footer-bottom d-flex justify-content-center align-items-center">
					<div className="d-flex flex-fill justify-content-center">
                        <h3>© 2021 Devteamsix. All Rights Reserved</h3>
                    </div>
					<span className="d-flex">
						<a href="https://www.arhamsoft.com/" target="_blank">
                            <img src="https://qrcode.info/images/ar-logo.svg" className="img-fluid" alt="" />
                        </a>
					</span>
				</div>
			</footer>
	);
}
export default Footer;