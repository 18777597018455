import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap";
import SiteLogo from "../../assets/images/logo.svg";
function Header(props) {
  console.log("props");
  const history = useHistory();
  const pathname = history.location.pathname;
  return (
    <header id="header" className="main-page-header">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <Container>
          <Row className="align-items-center w-100">
            <Col lg={2}>
              <strong className="logo">
                <Link to="/">
                  <img src={SiteLogo} alt=" Site Logo" />
                </Link>
              </strong>
            </Col>
            <Col lg={10} className="navigation-holder ms-auto">
              <Navbar expand="lg" className="pos-stat">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="flex-row justify-content-end"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/" ? "active" : ""
                        }`}
                        to="/"
                      >
                        Home{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/about-us" ? "active" : ""
                        }`}
                        to="/about-us"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/services" ? "active" : ""
                        }`}
                        to="/services"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/projects" ? "active" : ""
                        }`}
                        to="/projects"
                      >
                        Projects
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/our-team" ? "active" : ""
                        }`}
                        to="/our-team"
                      >
                        Our Team
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/our-partners" ? "active" : ""
                        }`}
                        to="/our-partners"
                      >
                        Our Partners
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/contact-us" ? "active" : ""
                        }`}
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </nav>
    </header>
  );
}
export default Header;
