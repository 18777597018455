import React from 'react';
import Header from '../Header/Header';
import OurTeamMembers from './OurTeamMembers';
import Footer from '../Footer/Footer';

function OurTeam(props) {
	return (
		<React.Fragment>
			<Header />
            <OurTeamMembers />
			<Footer />
		</React.Fragment>
	);
}
export default OurTeam;