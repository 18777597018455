import React from 'react';
import Header from '../Header/Header';
import OurPartnerInfo from '../OurPartners/OurPartnerInfo';
import Footer from '../Footer/Footer';

function OurPartnersPage(props) {
	return (
		<React.Fragment>
			<Header />
            <OurPartnerInfo />
			<Footer />
		</React.Fragment>
	);
}
export default OurPartnersPage;