import React, { useEffect } from "react";
import AOS from "aos";
import "animate.css";
import ZigZag from "../../assets/images/zigzag.png";

function Mission(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="dev-mission" className="mission--info ">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="devteam-data">
            <div className="getteam-block">
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                H<span>i</span>,
              </h1>
            </div>
            <div className="devhead">
              <h4
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                DevTeamSix Mission
              </h4>
            </div>
            <div className="devpara">
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                We are a full stack development team made up of Technology,
                Marketing and Business Professionals with the abilities to help
                in all areas of Cryptocurrency. Whether it is Decentralized
                Applications, Mobile Apps and Gaming, Digital Marketing or
                Software Development, DevTeamSix is the team to be confident in.
              </p>
            </div>
            <div className="button-holder">
              <a
                className="btn"
                href="/our-team"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
              >
                Meet The Team
              </a>
              <a
                className="btn"
                href="/about-us"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
              >
                Read More
              </a>
            </div>
            <div className="d-flex zigzag-area justify-content-center align-items-center">
              <div className="partitation">
                {/* <img src={ZigZag}  alt="" /> */}
              </div>
            </div>
            <div className="projects">
              <h4
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Check
              </h4>
              <div className="getteam-block">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  Our Projects
                </h1>
              </div>
            </div>
            <div className="tabs-area">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    CreamPYE
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    MiniDOGE
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    PYEChart
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    DOGEex
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    PYEWallet
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    MiniPets
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    PYESwap
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    MiniGames
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Mission;
