import React, {useEffect}  from 'react';
import AOS from "aos";
import CreampyLogo from '../../assets/images/creampy.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import ZigZag from '../../assets/images/zigzag.png';
import netImg from "../../assets/images/net.png";
import angularImg from "../../assets/images/angular.png";
import mongoImg from "../../assets/images/momgodb.png";
import htmlImg from "../../assets/images/html.png";
import person1 from "../../assets/images/person1.png";
import person2 from "../../assets/images/person-2.png";
import person3 from "../../assets/images/person-3.png";
import person4 from  "../../assets/images/person-4.png";
import slidImg from "../../assets/images/carosal-1.png";

import Slider from "react-slick";


function Projects(props) {

	useEffect(()=> {
		AOS.init();
	}, [])

	var settings = {
		dots: true,
		arrows:false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	  };

	return (
		<section id="about-project" className="project--info">
				<div className="container">
					<div className="tab-content" id="pills-tabContent">
						<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="d-flex justify-content-center align-items-center">
								<div className="d-flex">
									<div className="outer-box">
										<span className="creampy">
											<img src={CreampyLogo}  alt="" />
										</span>
									</div>
									<div className="aboutdetail">
										<h2 data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">About Project</h2>
										<p data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut lre et dolore magna aliqua</p>
									</div>
								</div>
							</div>
							<div className="row justify-content-center align-items-center">
								<div className="col-xxl-7 col-lg-6 col-md-12">
									<div className="about-inner-info">
										<h2 data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Technologies</h2>
										<p className="text-area"data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rlit, sed
											do eiusmod tempor incididunt ut lre et dolore magna aliqua.</p>
										<div className="logo-part">
											<ul className="logonames">
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={netImg} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={angularImg} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={mongoImg} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={htmlImg} alt="" />
												</li>
											</ul>
										</div>
										<div className="text-area">
											<h2 data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Team Collaboration</h2>
											<p data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
										</div>
										<div className="logo-part">
											<ul className="logonames">
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={person1} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={person2} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={person3} alt="" />
												</li>
												<li data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">
													<img src={person4} alt="" />
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-xxl-5 col-lg-6 col-md-12">
									<div className="mb-4 Sneak-peak ">
										<h2 data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic" class="aos-init aos-animate">A Sneak Peek</h2>
									</div>
									{/* <div className="technology-carousel owl-carousel owl-theme">
										<div className="image-deco">
											<img src={slidImg} alt="" />
										</div>
										<div>
											<img src={slidImg} alt="" />
										</div>
										<div>
											<img src={slidImg} alt="" />
										</div>
									</div> */}
									<Slider {...settings}>
										<div>
											<img src={slidImg} alt="" />
										</div>
										<div>
											<img src={slidImg} alt="" />
										</div>
										<div>
											<img src={slidImg} alt="" />
										</div>
									</Slider> 
									<div className="button-holder about-button"data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
										<a className="btn" href="/web/pricing">Check Project Details</a>
										<a href="/web/pricing">Discuss Your Requirements <FontAwesomeIcon icon={faAngleRight} /></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex zigzag-2--area justify-content-center align-items-center">
						<div className="partitation">
							{/* <img src={ZigZag}  alt="" /> */}
						</div>	
					</div>
				</div>
			</section>
	);
}
export default Projects;