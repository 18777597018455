import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, Button } from "react-bootstrap";
function ContactUs(props) {
  return (
    <section id="contactuspage" className="contact-form-info">
      <div className="container">
        <div class="contact-title-des text-center">
          <h2>GET IN TOUCH</h2>
          <p>Want to connect with a member of DT6? Contact Us!</p>
        </div>
        <div class="cont-box-wrap">
          <div class="row cont-inner-wrap justify-content-center">
            <div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div class="contact-box">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" placeholder="Subject" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={4} />
                  </Form.Group>
                </Form>
                <div class="button-holder checkmodel-button">
                  <a class="btn" href="#">
                    {" "}
                    Submit
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="contact-info">
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faMapMarker} />
                    <div class="info-holder">
                      <h3>Location</h3>
                      <p> Phoenix</p>
                    </div>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <div class="info-holder">
                      <h3>Email</h3>
                      <p>support@devteam.com</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="map-section mt-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9778520.74313386!2d-120.53209485657668!3d32.198831513425304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b12ed50a179cb%3A0x8c69c7f8354a1bac!2sPhoenix%2C%20AZ%2C%20USA!5e0!3m2!1sen!2s!4v1633527361700!5m2!1sen!2s"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9882.061433651352!2d-74.08658684695075!3d40.717068624970395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1630564288125!5m2!1sen!2s"
                  width="100%"
                  height="100%"
                  loading="lazy"
                ></iframe> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContactUs;
