import React from 'react';
import Header from '../Header/Header';
import OurProjectCheck from '../Projects/OurProjectCheck';
import Projects from '../Projects/Projects';
import Footer from '../Footer/Footer';

function ProjectPage(props) {
	return (
		<React.Fragment>
			<Header />
            <OurProjectCheck />
			<Projects />
			<Footer />
		</React.Fragment>
	);
}
export default ProjectPage;