import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import "./App.css";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/Services/Services";
import ProjectPage from "./components/ProjectPage/ProjectPage";
import OurTeam from "./components/OurTeam/OurTeam";
import OurPartnersPage from "./components/OurPartners/OurPartnersPage";
import ContactPage from "./components/ContactUs/ContactPage";
export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about-us" name="About Us" component={AboutUs} />
      <Route exact path="/services" name="Services" component={Services} />
      <Route exact path="/projects" name="Project" component={ProjectPage} />
      <Route exact path="/our-team" name="Our Team" component={OurTeam} />
      {/* <Route exact path="/our-partners" name="Our Partners" component={OurPartnersPage} /> */}
      <Route
        exact
        path="/contact-us"
        name="Contact Page"
        component={ContactPage}
      />
    </Switch>
  </Router>
);
