import React from "react";
import ZigZag from "../../assets/images/zigzag.png";

function About(props) {
  return (
    <section id="dev-mission" className="mission--info ">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="devteam-data">
            <div className="getteam-block">
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                H<span>i</span>,
              </h1>
            </div>
            <div className="devhead">
              <h4
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                DevTeamSix Mission
              </h4>
            </div>
            <div className="devpara">
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                We are a full stack development team made up of Technology,
                Marketing and Business Professionals with the abilities to help
                in all areas of Cryptocurrency. Whether it is Decentralized
                Applications, Mobile Apps and Gaming, Digital Marketing or
                Software Development, DevTeamSix is the team to be confident in.
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Our team is composed of like minded individuals who all have
                their eyes on the same goal, Leave a Legacy. In doing so, we
                will impact the whole world while sharing the knowledge and
                tools to bring economic growth to underdeveloped countries. With
                a passion for Charity, DevTeam Six will change the world.
              </p>
            </div>
            <div className="button-holder">
              <a
                className="btn"
                href="/about-us"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
              >
                Meet The Team
              </a>
              {/* <a
                className="btn"
                href="/about-us"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
              >
                Read More
              </a> */}
            </div>
            <div className="d-flex zigzag-area justify-content-center align-items-center">
              <div className="partitation">
                {/* <img src={ZigZag}  alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
