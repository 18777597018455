import React, { useEffect } from "react";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import team1 from "../../assets/images/team-1.png";
import team2 from "../../assets/images/team-2.png";
import team4 from "../../assets/images/team-4.png";
import team3 from "../../assets/images/team-3.png";
import team5 from "../../assets/images/team-5.png";
import team6 from "../../assets/images/team-6.png";
import Twix from "../../assets/images/twix.png";
import mainLogo from "../../assets/images/main-logo.png";
import beamPopup from "../../assets/images/beam-popup.png";
import President from "../../assets/images/uni-president.png";
import Atlas from "../../assets/images/Atlas.png";
import homePrint from "../../assets/images/home-print.png";
import Thirst from "../../assets/images/thirst.png";
import Beam from "../../assets/images/beam.png";
import ProfileImage from "../../assets/images/profile-image.png";
import ProfileMan from "../../assets/images/profile-man.png";
// import Slider from "react-slick";

function TeamLeader(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  // var settings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   centerMode: true,
  //   centerPadding: "120px",
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3,
  //         centerPadding: "100px",
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         centerPadding: "80px",
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         centerPadding: "60px",
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: false,
  //       },
  //     },
  //   ],
  // };

  return (
    <section id="Teamleader" className="Teamleader--section container-size">
      <div className="d-flex justify-content-center align-items-center">
        <div className="team-info">
          <h5
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-easing="ease-out-cubic"
            class="aos-init aos-animate"
          >
            Our Leadership
          </h5>
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-easing="ease-out-cubic"
            class="aos-init aos-animate"
          >
            Team
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-4 col-6">
            <div className="members-info">
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team1} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Deven Hammond, CCO of DevTeamSix, is a driven, motivated,
                      and ambitious leader. He thrives in developing and
                      implementing new communication strategies, serving as the
                      first point of contact between partners and company.{" "}
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Deven</h5>
                <h6>CCO </h6>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="members-info">
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team2} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Bill Spata, CEO of DevTeamSix, is an innovative leader,
                      speaker, and creator whose mission is to build advanced
                      technology that simplifies the crypto user’s
                      experience.With over 15 years of experience in building
                      and scaling successful businesses in many verticals, and
                      seeing a need to streamline the buying process of crypto,
                      Bill and Eric Andersen created DevTeamSix.{" "}
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Bill Spata</h5>
                <h6>CEO</h6>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="members-info">
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team4} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Ryan Winingham, CHO of DevTeamSix has an extensive
                      background in Charity Relations. With 5+ years of
                      philanthropy workWith 5+ years of philanthropy work,
                      supporting and hosting large scale charity functions along
                      with a strong drive to contribute to charities
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Ryan Winingham</h5>
                <h6>CHO</h6>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="members-info">
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team3} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Tommy Klindt, CSO of DevTeamSix is a problem solver at
                      heart. He loves to find solutions to complex issues. Tommy
                      coordinates with all of DevTeamSix’s developers to deliver
                      top notch products and platforms.
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Tommy Klindt</h5>
                <h6>CSO</h6>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="members-info">
              {/* <div className="image-outerbox">
                <img src={team5} alt="" />
              </div> */}
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team5} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Eric Andersen, CTO of DevTeamSix, is a Full Stack
                      Developer focused on bringing simplicity and mass adoption
                      to Blockchain Technologies.His professional career in
                      development started in 1998. In 2003 he and (2) partners
                      introduced drag-and-drop website development allowing
                      people with no technological experience to build and
                      maintain their own website.
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Eric Andersen</h5>
                <h5>CTO</h5>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="members-info">
              <div class="team-card">
                <div class="team-card-inner">
                  <div class="team-card-front">
                    <img src={team6} alt="" />
                  </div>
                  <div class="team-card-back">
                    <p>
                      Ebony McCray, CMO of DevTeamSix, strives to amplify
                      purpose through marketing which will create a movement.
                      Ebony has applied this philosophy to help scale several
                      businesses successfully in the past 5 years.{" "}
                    </p>

                    <div class="button-holder services-button text-center">
                      <a class="btn" href="/our-team">
                        View Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center individual-data">
                <h5>Ebony McCray</h5>
                <h6>CMO</h6>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/signup" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Slider {...settings} className="Teamleader-carousel">
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team2} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Ebony McCray</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team1} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Bill Spata</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team4} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Eric Turner</h6>
              <h6>CRO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team5} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Deven Hammond</h6>
              <h6>CCO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team2} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Ebony McCray</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team1} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Bill Spata</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team5} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Deven Hammond</h6>
              <h6>CCO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team5} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Deven Hammond</h6>
              <h6>CCO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team2} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Ebony McCray</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team1} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Bill Spata</h6>
              <h6>CMO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team4} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Eric Turner</h6>
              <h6>CRO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="members-info">
            <div className="image-outerbox">
              <img src={team5} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h6>Deven Hammond</h6>
              <h6>CCO</h6>
              <div className="social-icons">
                <a href="https://www.facebook.com/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/signup" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}
      <div id="proud-team" className="empower-part">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="team-info">
              <h5
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                We are Proud to Empowered
              </h5>
            </div>
          </div>
          <div className="row justify-content-center align-items-center alert--part">
            <div className="col-md-3 mb-md-0 mb-3">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                140.758
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Active Hours
              </p>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                4050
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Employees
              </p>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                1200
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Successful Projects
              </p>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h3
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                1000+
              </h3>
              <p
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Hapy Clients
              </p>
            </div>
          </div>
          {/* <div className="best-partners">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="best-partners--iner">
                  <h4
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-out-cubic"
                    class="aos-init aos-animate"
                  >
                    We Work With the
                  </h4>
                  <h1
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-out-cubic"
                    class="aos-init aos-animate"
                  >
                    Best Partners
                  </h1>
                  <p
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-out-cubic"
                    class="aos-init aos-animate"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilid
                    tincidunt, quam nec porta accumsan, nisl quam mattis neque,
                    in digissim ser ipsum arcu quis mmotrices grado viverra
                    maecenas accsis.{" "}
                  </p>
                  <div className="button-holder services-button">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a className="btn" href="/web/pricing">
                        Become Our Partner
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="row justify-content-md-end justify-content-center align-items-center logo--button">
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={Twix} alt="" />
                      </a>
                    </div>
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body model-iner-content">
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="d-flex ">
                                <img src={mainLogo} alt="" />
                              </div>
                              <div className="d-flex ms-3 link-icon">
                                <a href="https://www.link.com/" target="_blank">
                                  <FontAwesomeIcon icon={faLink} />
                                </a>
                              </div>
                              <div className="d-flex ms-3">
                                <img src={beamPopup} alt="" />
                              </div>
                            </div>
                            <div className="">
                              <h1>DevTeamSix and Beam</h1>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut lae et dolore magna aliqua.
                              </p>
                              <div className="button-holder checkmodel-button">
                                <a className="btn" href="/web/pricing">
                                  Check Detail
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={President} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={Atlas} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={homePrint} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={Thirst} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right logo-decoration gap">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                      class="aos-init aos-animate"
                    >
                      <a
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={Beam} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="clients-love-part">
            <div className="d-flex justify-content-center align-items-center">
              <h5
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-easing="ease-out-cubic"
                class="aos-init aos-animate"
              >
                Clients Love With Us
              </h5>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <div className="informstion-boxes">
                    <p>
                      Quis ipsum suspendisse ultrices gravida. rem ipsum dolor
                      sit amet, consectetur adipiscing elit, sed do bod tempor
                      incididunt ut labore et dolore magna aliqua.{" "}
                    </p>
                    <div className="row  justify-content-center align-items-center">
                      <div className="col-md-2 col-3">
                        <img src={ProfileImage} alt="" />
                      </div>
                      <div className="col-md-10 col-9">
                        <h6>Eric Turner</h6>
                        <h6 className="mb-0">Owner of PETGames</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-lg-0 mb-4">
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic"
                  class="aos-init aos-animate"
                >
                  <div className="informstion-boxes">
                    <p>
                      Quis ipsum suspendisse ultrices gravida. rem ipsum dolor
                      sit amet, consectetur adipiscing elit, sed do bod tempor
                      incididunt ut labore et dolore magna aliqua.{" "}
                    </p>
                    <div className="row  justify-content-center align-items-center">
                      <div className="col-md-2 col-3">
                        <img src={ProfileMan} alt="" />
                      </div>
                      <div className="col-md-10 col-9">
                        <h6>Eric Turner</h6>
                        <h6 className="mb-0">Owner of PETGames</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TeamLeader;
