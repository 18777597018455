import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import team1 from "../../assets/images/team-1.png";
import team2 from "../../assets/images/team-2.png";
import team3 from "../../assets/images/team-3.png";
import team4 from "../../assets/images/team-4.png";
import team5 from "../../assets/images/team-5.png";
import team6 from "../../assets/images/team-6.png";
function OurTeamMembers(props) {
  return (
    <section id="Teamleader" className="Teamleader--section detail-info-page">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team1} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Deven Hammond</h5>
              <h6>Chief Communications Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction height-content">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    deven@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.twitter.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between pt-2 textwhite">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5">
                  <div className="social-icons">
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Deven</h2>
                <p>
                  Deven Hammond, CCO of DevTeamSix, is a driven, motivated, and
                  ambitious leader. He thrives in developing and implementing
                  new communication strategies, serving as the first point of
                  contact between partners and company. He has had great success
                  in previous business experiences managing and building large
                  partnerships with the likes of Amazon.com, Inc. His continuous
                  enthusiasm and professionalism helps cultivate the culture of
                  DevTeamSix and all of its communities.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team2} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Bill Spata</h5>
              <h6>Chief Executive Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    bill@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.twitter.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between textwhite pt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5">
                  <div className="social-icons">
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Bill</h2>
                <p>
                  Bill Spata, CEO of DevTeamSix, is an innovative leader,
                  speaker, and creator whose mission is to build advanced
                  technology that simplifies the crypto user’s experience. With
                  over 15 years of experience in building and scaling successful
                  businesses in many verticals, and seeing a need to streamline
                  the buying process of crypto, Bill and Eric Andersen created
                  DevTeamSix.
                </p>
                <p className="pt-4">
                  DevTeamSix is building blockchain technology for real life use
                  cases. The team’s goal is building monetary infrastructure in
                  underdeveloped countries that will encourage the
                  entrepreneurial spirit to stimulate economic growth. By
                  creating a charity aspect to the tokenomics, we are fueling
                  the mission of making an impact across all nations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team4} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Ryan Winingham</h5>
              <h6>Chief Heart Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction height-content">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    ryan@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.twitter.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between textwhite pt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5"></div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Ryan</h2>
                <p>
                  Ryan Winingham, CHO of DevTeamSix has an extensive background
                  in Charity Relations. With 5+ years of philanthropy work,
                  supporting and hosting large scale charity functions along
                  with a strong drive to contribute to charities world wide
                  providing a global impact, Ryan strives to share her Heart
                  with the world through Cryptocurrency.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team3} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Tommy Klindt</h5>
              <h6>Chief Strategy Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction height-content">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    tommy@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.twitter.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between textwhite pt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5">
                  <div className="social-icons">
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Tommy</h2>
                <p>
                  Tommy Klindt, CSO of DevTeamSix is a problem solver at heart.
                  He loves to find solutions to complex issues. Tommy
                  coordinates with all of DevTeamSix’s developers to deliver top
                  notch products and platforms. His diverse and successful
                  professional background has given him many tools to bring
                  success to the team and all holders of DevTeamSix projects.
                  Tommy strives to make a meaningful impact on people around the
                  world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team5} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Eric Andersen</h5>
              <h6>Chief Technology Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    Eric@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between textwhite pt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5"></div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Eric</h2>
                <p>
                  Eric Andersen, CTO of DevTeamSix, is a Full Stack Developer
                  focused on bringing simplicity and mass adoption to Blockchain
                  Technologies. His professional career in development started
                  in 1998. In 2003 he and (2) partners introduced drag-and-drop
                  website development allowing people with no technological
                  experience to build and maintain their own website. Bill Spata
                  joined the team in 2009 as a partner developing a (2) year
                  online interactive educational system growing the company to
                  over (150) full-time in-house staff bringing knowledge and
                  clarity to technology.
                </p>
                <p className="pt-4">
                  Bill and Eric moved into blockchain development in 2018
                  working on a new type of blockchain utilizing distributed
                  validation through light nodes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-3">
            <div class="image-outerbox">
              <img src={team6} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center individual-data">
              <h5>Ebony McCray</h5>
              <h6>Chief Marketing Officer</h6>
            </div>
          </div>
          <div className="col-md-9">
            <div className="intorduction height-content">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h2>Contact Info</h2>
                </div>
                <div className="d-flex me-5 pe-5">
                  <h2>Social</h2>
                </div>
              </div>
              <div className="d-flex justify-content-between personal-info">
                <div className="d-flex justify-content-center align-items-center">
                  <a class="btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faEnvelopeOpen} />
                    Ebony@devteamsix.com
                  </a>
                </div>
                <div className="d-flex  me-5 pe-5 justify-content-center align-items-center">
                  <div className="social-icons">
                    <a href="https://www.twitter.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between textwhite pt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a className=" btn" href="/deven@devteamsix.com">
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    +82 558 878 11 22
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center me-5 pe-5">
                  <div className="social-icons">
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-felx little-detail">
                <h2>A little bit about Ebony</h2>
                <p>
                  Ebony McCray, CMO of DevTeamSix, strives to amplify purpose
                  through marketing which will create a movement. Ebony has
                  applied this philosophy to help scale several businesses
                  successfully in the past 5 years. Her speciality is marketing
                  through social media, including Facebook & Instagram.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurTeamMembers;
